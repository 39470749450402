import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AppMaterialModule } from 'app/app-material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    AppMaterialModule,
  ],
  declarations: [
    BreadcrumbComponent

  ],
  exports: [
    BreadcrumbComponent,

  ],
  providers: []
})

export class SurvaleCommonModule {
}

