import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class RoutingRulesService extends BaseHttpService {
  private resource = 'routing-rules';

  constructor(public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService) {
    super(http);
  }

  getRules() {
    return this.get('routing-rules');
  }

  getRule(id: string) {
    return this.get(`${this.resource}/${id}`);
  }

  addRule(payload: any) {
    return this.post(this.resource, payload);
  }

  editRule(payload: any, id: string): Observable<any> {
    return this.put(this.resource, payload, id);
  }

  deleteRule(id: string): Observable<any> {
    return this.delete(this.resource, id);
  }
}

