import { Injectable } from '@angular/core';
import { CompactType, DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2';
import { MatDialogConfig } from '@angular/material/dialog';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { Observable, Observer } from 'rxjs';
import { ConfigServiceData } from './config.service.data';

enum LocalStorageKeys {
  CurrentUser = 'current-user',
  AuthToken = 'auth-token',
  DateRange = 'date-range',
  DateRangeLabel = 'date-range-label',
}

export enum DashboardWidgetTypes {
  questionChart = 'questionChart',
  surveyStatsChart = 'surveyStatsChart',
  visitsBreakdownChart = 'visitsBreakdownChart',
  commentsWidget = 'commentsWidget',
  NPSMetricsWidget = 'NPSMetricsWidget',
  NPSBenchmarkWidget = 'NPSBenchmarkWidget',
  NPSTrendWidget = 'NPSTrendWidget',
  completionWidget = 'completionWidget',
  indexMetricsWidget = 'indexMetricsWidget',
  indexTrendWidget = 'indexTrendWidget',
  tableWidget = 'tableWidget',
  tableWidgetMC = 'tableWidgetMC',
  tableWidgetClickThrough = 'tableWidgetClickThrough',
  tableWidgetCompletion = 'tableWidgetCompletion',
  compQuestionTrendWidget = 'compQuestionTrendWidget',
  compositeMetricsWidget = 'compositeMetricsWidget',
  compIndexTrendWidget = 'compIndexTrendWidget',
  priorityMatrix = 'priorityMatrix',
  imageWidget = 'imageWidget',
  surveysSummary = 'surveysSummary',
  campaignsSummary = 'campaignsSummary',
  emailListsSummary = 'emailListsSummary',
  emailDeliverySummary = 'emailDeliverySummary',
}

export enum SurveyQuestionTypes {
  MultipleChoice = 'Multiple Choice',
  ExtractedQuestionMultipleChoice = 'Extracted Question-Multiple Choice',
  HiLowScale = 'High/Low Scale',
  NPS = 'Net Promoter Score',
  LongAnswer = 'Long Answer',
  ShortAnswer = 'Short Answer',
  MultipleSelect = 'Multiple Select',
  Introduction = 'Introduction',
  Matrix = 'Matrix',
  MatrixQuestion = 'Matrix-Question',
  MatrixQuestionGroup = 'Matrix-Group-Question',
  Email = 'Email',
  MatrixS1_10 = 'Matrix Scale 1-10',
  MatrixS1_5 = 'Matrix Scale 1-5',
  MatrixS1_3 = 'Matrix Scale 1-3',
  MatrixGroupS1_5 = 'Matrix Scale 1-5 Group Question',
  MatrixGroupS1_3 = 'Matrix Scale 1-3 Group Question',
  MatrixGroupS1_10 = 'Matrix Scale 1-10 Group Question',
  RadioButton = 'Radio Button',
  Checkbox = 'Checkbox',
  TalentPool = 'Talent Pool',
  Reference = 'Reference',
  SubGroupMatrixScale = 'Sub Question-Matrix-Scale'
}

export enum SurveyFilterType {
  TypeN = 'typeN',
  TypeR = 'typeR'
}

// this needs to be in sync with appUtil `PERMISSION_LIST`
export enum PermissionTypes {
  Views = 'Views',
  Cande = 'Cande',
  Dashboards = 'Dashboards',
  Surveys = 'Surveys',
  Users = 'Users',
  Clients = 'Manage Clients',
  ManageAPIIntegration = 'Manage API Integration',
  Campaigns = 'Campaigns',
  EmployeeCampaigns = 'EmployeeCampaigns',
  EmployeeCampaignResults = 'EmployeeCampaignResults',
  CampaignResults = 'Campaign Results',
  Indexes = 'Indexes',
  Sites = 'Sites',
  Roles = 'Roles',
  GDPR = 'GDPR',
  GDPRLog = 'GDPR Log',
  EmailLists = 'Email Lists',
  EmployeeLists = 'Employee Lists',
  PromoCodes = 'PromoCodes',
  ViewHiringManager = 'View Hiring Manager',
  ViewRecruiter = 'View Recruiter',
  ViewEmployee = 'View Employee',
  ViewSurveyAboutInformation = 'View Survey About Information',
  ViewOtherInformation = 'View Other Information',
  ViewEmailList = 'View Email List',
  ViewReports = 'View Reports',
  SurveyInsights = 'Survey Insights',
  ManageOAuth = 'manageOAuth',
  ManageRoles = 'manageRoles',
  ApiCallLogs = 'apiCallLogs',
  CanEmailDashboardsSnapshots = 'canEmailDashboardsSnapshots',
  CanEmailInsightsSnapshots = 'canEmailInsightsSnapshots',
  EmailListDataRetention = 'EmailListDataRetention',
  TwoFactorAuthentication = 'TwoFactorAuthentication',
  DeleteSurveyResponse = 'DeleteSurveyResponse',
  ShareDashboards = 'ShareDashboards',
  ShareFilteredDashboards = 'ShareFilteredDashBoards',
  EditWidgets = 'EditWidgets',
  SendBulkEmailReminders = 'Send Bulk Email Reminders',
  ReleaseNotes = 'Survale Community',
  SurvaleStart = 'Survale Start',
  CloneCampaign = 'Clone Campaign',
  CampaignScheduleView = 'Campaign Schedule View',
  ViewDashboardFilters = 'View Dashboard Filters',
  RoutingRules = 'RoutingRules',
  URLShortener = 'URL Shortener',
  TalentImpact = 'Talent Impact',
  SentimentAnalysis = 'Sentiment Analysis',
  SentimentAnalysisAskQuestion = 'Sentiment Analysis Ask Question',
  ObjectTagging = 'Object Tagging',
  WordCloud = 'Word Cloud',
  SetPassword = 'Set Password',
  SftpAccess = 'Access sftp',
  AddWidgetToDashboard = 'Add Widget To Dashboard',
  ViewBESiteSettings = 'View BE Site Settings',
  ViewCandidateInfoRawData = 'View Candidate Info Raw Data',
  DeleteUser = 'Delete User',
  FloatingFilters = 'Floating Filters',
  ShareFloatingFilters = 'Share Floating Filters',
}

// * this needs to be in sync with `PERMISSION_LIST` util/app-util.js
// * this needs to be in sync with src/app/services/config.service.ts
// * this needs to be in sync with `PermissionTypes` api/api-common-data.js 
export enum PermissionOptions {
  Edit = 'hasEdit',
  View = 'hasView'
}

export enum EmployeeCampaignTypes {
  // Anniversary = 'Anniversary',  // removing Anniversary as it was never used
  DateBased = 'DateBased',
  NewEmployees = 'New Employees',
  TerminatedEmployees = 'Terminated Employees',
}

export enum SurveyQuestionContainerTypes {
  CompareQuestion = 'compareQuestion',
  Insights = 'insights',
  SurveyResult = 'surveyResult',
  EmployeeCampaign = 'employeeCampaign'
}

export enum WidgetFilterKeyType {
  SurveyId = 'surveyId',
  EmployeeCampaignId = 'employeeCampaignId'
}

export enum UserTypes {
  AppUser = 'appUser',
  InsightUser = 'insightUser',
  ManagerUser = 'managerUser',
}

export enum AppFeatures {
  DashboardFilters = 'dashboardFilters',
  QBR = 'qbr',
  CompareQuestions = 'compareQuestions',
  FilterManualEntry = 'filterManualEntry',
  ExportComments = 'exportComments',
  ExportData = 'exportData',
  ToggleSurveys = 'toggleSurveys',
  ToggleIndexes = 'toggleIndexes',
  ViewSurvey = 'viewSurvey',
  ShareInsight = 'shareInsight',
  SaveSurveyFilter = 'saveSurveyFilter',
  DeleteSurveyFilter = 'deleteSurveyFilter',
  SentimentAnalysis = 'SentimentAnalysis',
}

@Injectable()
export class ConfigService {

  public static config: any = {
    survaleEnv: environment.name,
    apiHost: environment.apiHost,
    services: {},
    shortURLHost: environment.shortURLHost,
    errors: { codes: {} },
    hostUri: environment.hostUri,
    cdn: environment.cdn,
    enableInspectLet: environment.enableInspectLet,
    googleRecaptchaSiteKey: environment.googleRecaptchaSiteKey,
    googleRecaptchaSiteKeyV3: environment.googleRecaptchaSiteKeyV3,
    // cloudinary: environment.cloudinary,
    surveyUrl: environment.surveyUrl,
    loadDashboardsInMenu: true,
    survaleReferEmailListTitle: 'Survale Refer List',
    gaCode: environment.gaCode,
    productFruitCode: environment.productFruitCode,
    productFruitCodePro: environment.productFruitCode_pro,
    nextJSSurveyUrl: environment.nextJSSurveyUrl,
    portalHost: environment.portalHost,
    alternateSurveyDomains: environment.alternateSurveyDomains,
    // clientIdsUsingNextJSSurveyURL: environment.clientIdsUsingNextJSSurveyURL,
    maxOptionsInMCToShowTable: 30,
    clientsWithAIEmbeddings: environment.clientsWithAIEmbeddings,
    slackAppId: environment.slackAppId
  };

  public static environment: any = environment;

  public static defaultPieChartColors = [
    '#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a',
    '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94',
    '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d',
    '#17becf', '#9edae5'
  ];

  public static defaultMultiSelectColors = ['#9467bd'];

  private static defaultMatDialogConfig: MatDialogConfig = {
    disableClose: false,
    minWidth: '50vw',
    position: { top: '10%' }
  };

  public localStorageKeys = LocalStorageKeys;
  public DashboardWidgetTypes;


  static get ConfigServiceData(): typeof ConfigServiceData {
    return ConfigServiceData;
  }

  static authProviderMap(): any {
    const authProviders = {
      'eu-prod': {
        545: [
          { title: 'Survale' },
          { title: 'Biolife', authUrl: 'v0/auth/takeda-saml' },
        ]
      },
      prod: {

        // '806' // key-bank-sandbox-saml
        806: [ // uses 810
          { title: 'Survale' },
          { title: 'Key Bank - Sandbox', authUrl: 'v0/auth/key-bank-sandbox-saml' },
        ],
        // 516: [ // all samls except Exelon are in the db
        //   {title: 'Survale'},
        //   {title: 'Exelon', authUrl: 'v0/auth/exelon-saml'},
        // ],
        747: [ // uses 759
          { title: 'Survale' },
          { title: 'CHR Saml', authUrl: 'v0/auth/chr-saml' }
        ],
        3: [
          { title: 'Survale' },
          { title: 'Google', authUrl: 'v0/auth/google' }
        ],
        1: [
          { title: 'Survale' },
          { title: 'Google', authUrl: 'v0/auth/google' },
        ],
      },
      staging: {
        1: [
          { title: 'Survale' },
          { title: 'Google', authUrl: 'v0/auth/google' },
        ],
      }
    };
    if (!authProviders[environment.name]) {
      console.error(`authProviders.${environment.name} is nill`, environment.name);
      const r = prompt('What env is this. \, 1 for staging\n 2 for prod\n 3 for eu prod');
      if (r === '1') {
        environment.name = 'staging';
      } else if (r === '2') {
        environment.name = 'prod';
      } else if (r === '3') {
        environment.name = 'eu-prod';
      }
    }
    return authProviders[environment.name];
  }

  static getNumericQuestionColors(): string[] {
    return ['#32ae4a', '#111', '#ef3c24'];
  }

  static getStandardNumberScaleColors(): string[] {
    return ['#ed462f', '#a7aaac', '#32ae4a'];
    // #1ab394
  }

  static getStandardSurvaleColors(): any {
    return { red: '#ed462f', grey: '#a7aaac', green: '#1ab394', orange: '#f1c321' };
  }

  static getPasswordValidationRegEx(): any {
    return new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
  }

  static getCommonHighChartOptions(): Highcharts.Options {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },
      plotOptions: {
        series: { states: { inactive: { opacity: 1 } } }
      },
      title: { text: '' },
      credits: { enabled: false },
      lang: { noData: 'No data to display!', loading: 'Loading ...' },
    };
  }

  static getCandeCompareToOptions(): any[] {
    return [
      {
        label: 'ALL',
        tooltip: 'All Participant\'s',
        key: 'all',
        valueField: 'AllClientsAverage',
        benchmarkTitle: 'TalentBoard - Benchmark derived from all participant\'s',
        benchmarkType: 'TB-A'
      },
      {
        label: 'WIN',
        tooltip: 'Award Winners',
        key: 'winners',
        // its really inclusive of all regions now
        // we can still keep the name as namWinnersBenchmark
        valueField: 'namWinnersBenchmark',
        benchmarkTitle: 'TalentBoard - Benchmark derived from all Winners',
        benchmarkType: 'TB-W'
      }
    ];
  }

  static getDefaultGridsterOptions(): GridsterConfig {
    return {
      // setGridSize: true,
      gridType: GridType.ScrollVertical,
      compactType: CompactType.None,
      margin: 5,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      minCols: 12,
      maxCols: 12,
      minRows: 12,
      maxRows: 200, // need this for Cande Long Surveys
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      // fixedColWidth: 50,
      // fixedRowHeight: 50,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: true,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: true,
      emptyCellDragMaxCols: 100,
      emptyCellDragMaxRows: 100,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: true,
      },
      swap: false,
      swapWhileDragging: false,
      pushItems: true, // if this true then items move between left and right
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: false, south: true, west: false },
      pushResizeItems: false,
      displayGrid: DisplayGrid.OnDragAndResize,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false
    };
  }

  static getDialogConfigSmall(): MatDialogConfig {
    const c = _.cloneDeep(ConfigService.defaultMatDialogConfig);
    c.minWidth = '30vh';
    return c;
  }

  static getDialogConfig(): MatDialogConfig {
    return _.cloneDeep(ConfigService.defaultMatDialogConfig);
  }

  static getDialogConfigWide(): MatDialogConfig {
    const c = _.cloneDeep(ConfigService.defaultMatDialogConfig);
    c.minWidth = '80vw';
    return c;
  }

  static getIndexImportanceLookup(): Observable<any> {
    // so that it can come from an API l8r
    return new Observable((observer: any) => {
      const o = [
        { keyword: 'career site', multiplier: 0.8 }, // 40%
        { keyword: 'application', multiplier: 0.4 }, // 20%
        { keyword: 'assessment', multiplier: 0.4 }, // 20%
        { keyword: 'scheduling', multiplier: 0.3 }, // 10%
        { keyword: 'recruiter', multiplier: 1.6 }, // 70%
        { keyword: 'phone screen', multiplier: 1.6 }, // 70%
        { keyword: 'interview satisfaction', multiplier: 2.1 }, // 80%
        { keyword: 'interview experience', multiplier: 2.1 }, // 80%
        { keyword: 'declined offer', multiplier: 2.5 }, // 85%
        { keyword: 'quality of hire', multiplier: 1.8 }, // 75%
        { keyword: 'onboarding', multiplier: 2.1 }, // 80%
        { keyword: 'new hire', multiplier: 2.5 }, // 90%
        { keyword: 'exit', multiplier: 1.5 }, // 65%
      ];
      observer.next(o);
      observer.complete();
    });
  }

  static getTimeZones(): typeof ConfigServiceData.TIME_ZONES {
    return ConfigServiceData.TIME_ZONES;
  }

  static commonEmployeeFields(): typeof ConfigServiceData.COMMON_EMPLOYEE_FIELDS {
    return ConfigServiceData.COMMON_EMPLOYEE_FIELDS;
  }

  static commonEmailFields(): typeof ConfigServiceData.COMMON_EMAIL_FIELDS {
    return ConfigServiceData.COMMON_EMAIL_FIELDS;
  }

  static getListIndexedFields(): typeof ConfigServiceData.LIST_INDEXED_FIELDS {
    return ConfigServiceData.LIST_INDEXED_FIELDS;
  }

  static generateCustomFieldNumericHash(s: string): number {
    const val = s.split('').reduce((a, b): any => {
      // eslint-disable-next-line no-bitwise
      a = ((a << 5) - a) + b.charCodeAt(0);
      // eslint-disable-next-line no-bitwise
      return a & a;
    }, 0);
    return -1 * Math.abs(val);
  }

  static getListIndexedFieldsWithHash(): any[] {
    const indexedFields = _.map(ConfigServiceData.LIST_INDEXED_FIELDS, (value, key) => ({
      key,
      value,
      hash: ConfigService.generateCustomFieldNumericHash(key),
      questionType: undefined
    }));
    indexedFields.unshift({
      key: 'interviewers',
      value: 'Interviewers',
      hash: -35913644,
      questionType: SurveyQuestionTypes.MultipleSelect
    });
    return indexedFields;
  }

  static getEmbeddedQuestionMarker(): string {
    return '~~embedded-survey-question~~';
  }

  getConfig(): typeof ConfigService.config {
    return ConfigService.config;
  }

  widgetSizes(): any {
    return {
      standard: { width: 4, height: 5 }
    };
  }

  getDateRangePickerOptions(): any {
    return {
      locale: {
        format: 'YYYY MMM DD',
        separator: ' to ',
        // cancelLabel: 'Cancel',
        applyLabel: 'Apply'
      },
      autoApply: false,
      alwaysShowCalendars: false,
      showCancel: true,
      showClearButton: false,
      linkedCalendars: true,
      singleDatePicker: false,
      showWeekNumbers: false,
      showISOWeekNumbers: false,
      customRangeDirection: false,
      lockStartDate: false,
      closeOnAutoApply: true,
      ranges: {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        // 'Last 60 Days': [moment().subtract(59, 'days'), moment()],
        'Last 90 Days': [moment().subtract(89, 'days'), moment()],
        // 'Last 120 Days': [moment().subtract(119, 'days'), moment()],
        'Last 6 Months': [moment().subtract(6, 'months'), moment()],
        'Last 12 Months': [moment().subtract(12, 'months'), moment()],
        'Current Year': [moment().startOf('year'), moment()],
      }
    };
  }

  getSurveyUrl(clientId: number, isBE: boolean, isCande: boolean): string {
    return ConfigService.config.nextJSSurveyUrl;
    //   const clientIdsUsingNextJSSurveyURL: number[] = environment.clientIdsUsingNextJSSurveyURL;
    //   if (isCande || clientId > 1048 || isBE || (clientIdsUsingNextJSSurveyURL.length === 1 && clientIdsUsingNextJSSurveyURL[0] === -1)) {
    //     return ConfigService.config.nextJSSurveyUrl;
    //   }
    //   return clientIdsUsingNextJSSurveyURL.indexOf(clientId) > -1 ?
    //     ConfigService.config.nextJSSurveyUrl :
    //     ConfigService.config.surveyUrl;
  }
}
