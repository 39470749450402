// Core modules
import {Component, OnInit, OnChanges, Input} from '@angular/core';
import {FormControl, FormGroup, FormBuilder} from '@angular/forms';
import {first, firstValueFrom} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';

// Application services
import {ClientService} from 'app/services/client.service';
import { RoutingRulesService } from 'app/services/routing-rules.service';

import * as _ from 'lodash';

/**
 * Immediate alert settings
 */
@Component({
  selector: 'app-immediate-settings',
  templateUrl: './immediate-settings.component.html',
  styleUrls: ['./immediate-settings.component.scss']
})
export class ImmediateSettingsComponent implements OnInit, OnChanges {
  @Input() settings;
  @Input() operationalFieldRules: any[] = [];

  public surveyEscalationSettings: any;
  public immediateAlertForm: FormGroup;
  public operationalFieldRulesForm: FormGroup;
  public operationalFields = [];
  public routingRulesOfOperationalFields = [];
  public selectedRoutingRules = [];
  public isValidRoutingRule = false;
  public defaultEmails = [];
  private isLoading = false;

  /**
   * Life cycle method
   * @param clientService ClientService
   * @param fb FormBuilder
   */
  constructor(
    private clientService: ClientService,
    private fb: FormBuilder,
    private routingRulesService: RoutingRulesService,
  ) {}

  async loadData(): Promise<void> {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.selectedRoutingRules = [];
    this.operationalFields = [];
    this.routingRulesOfOperationalFields = [];
    const data: any = await firstValueFrom(this.routingRulesService.getRules());
    for (const rule of data.body) {
      if (rule.isAvailable) {
        rule.isSelected = !!_.find(this.operationalFieldRules, r => r.routingRuleId === rule.id);
        if (rule.isSelected) {
          this.selectedRoutingRules.push(rule);
        }
        this.operationalFields.push(rule);
        this.routingRulesOfOperationalFields.push(rule);
      }
    }
    if (this.settings) {
      this.defaultEmails = _.map(this.settings.surveyEscalation?.reportToEmail || [], 'email');
    }
    this.isLoading = false;
}
  
  /**
   * Finding the default survey escalation settings and initialize form
   */
  async ngOnInit(): Promise<void> {
    this.surveyEscalationSettings = this.clientService.getDefaultSurveyEscalation().immediateAlert;
    this.initializeForm();
    await this.loadData();
  }

  /**
   * Sets the client survey escalation values received from parent component
   */
  async ngOnChanges(): Promise<void> {
    if (this.settings && this.settings.surveyEscalation && this.settings.surveyEscalation.immediateAlert) {
      this.surveyEscalationSettings = this.settings.surveyEscalation.immediateAlert;
      console.log(this.immediateAlertForm);
      this.immediateAlertForm.controls.minScore.patchValue(this.surveyEscalationSettings.minScore);
      this.immediateAlertForm.controls.alertLongComments.patchValue(this.surveyEscalationSettings.alertLongComments);
      await this.loadData();
    }
  }

  /**
   * Initialize form
   */
  initializeForm(): void {
    this.immediateAlertForm = this.fb.group({
      minScore: new FormGroup({
        enabled: new FormControl(this.surveyEscalationSettings.minScore.enabled),
        value: new FormControl(this.surveyEscalationSettings.minScore.value)
      }),
      alertLongComments: new FormGroup({
        enabled: new FormControl(this.surveyEscalationSettings.alertLongComments.enabled),
        value: new FormControl(this.surveyEscalationSettings.alertLongComments.value)
      }),
    });
    this.operationalFieldRulesForm = this.fb.group({
      operationalFieldRule: new FormControl(''),
    });
  }

  onRoutingRuleChange(): void {
    this.isValidRoutingRule = this.operationalFieldRulesForm.controls.operationalFieldRule.value &&
      !this.operationalFieldRulesForm.controls.operationalFieldRule.value.isSelected;
    this.operationalFieldRules = [];
    for(const rule of this.selectedRoutingRules) {
      this.operationalFieldRules.push({routingRuleId: rule.id, isEnabled: true});
    }
  }

  addRoutingRule(): void {
    if (!this.operationalFieldRulesForm.controls.operationalFieldRule.value) {
      return;
    }
    const selectedRule = this.operationalFieldRulesForm.controls.operationalFieldRule.value;
    selectedRule.isSelected = true;
    this.selectedRoutingRules.push(selectedRule);
    this.operationalFieldRulesForm.reset();
    this.onRoutingRuleChange();
  }

  removeRoutingRule(index: any): void {
    const selectedRule = this.selectedRoutingRules[index];
    this.selectedRoutingRules.splice(index, 1);
    for (const rule of this.routingRulesOfOperationalFields) {
      if (rule.id === selectedRule.id) {
        rule.isSelected = false;
      }
    }
  }
}
