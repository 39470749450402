import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { LocalStorageService } from 'angular-2-local-storage';
import { ConfigService, SurveyQuestionTypes, SurveyFilterType } from './config.service';
import { AppCache } from '../components/survale-common/cache/app.cache';
import * as _ from 'lodash';

export enum ReloadObjectTypes {
  Insights = 'insights',
  SurveyFilters = 'survey-filters',
  DashboardFilters = 'dashboard-filters',
  // SelfSurveyFilters = 'self-survey-filters', // not used
  // SelfDashboardFilters = 'self-dashboard-filters', // not used
}

export class EventsServiceDateRange {
  constructor(public startDate: moment.Moment, public endDate: moment.Moment) {
  }
}

export class AddedDashboard {
  constructor(public id: number, public viewId: number, public title: string, public dashboard: any) {
  }
}

export class DeletedDashboard {
  constructor(public id: number, public viewId: number) {
  }
}

export class QuestionScore {
  private readonly scoreOn100PtScale: number = 0;

  constructor(public questionId: number,
    public score: number,
    public scale: number,
    public responses: number,
    public tag: any,
    public filters: Array<SurveyFilter>) {
    this.scoreOn100PtScale = Math.round((100 * score / scale));
  }

  getScore(): number {
    return this.scoreOn100PtScale;
  }
}

export class SurveyFilter {
  // id: any;
  constructor(public questionId: string,
    public title: string,
    public questionType: SurveyQuestionTypes,
    public filterType: SurveyFilterType,
    public selectedValues: Array<string | number>, // this is used
    public displayedValues: Array<string>, // this is used
    public filterOptions: Array<string>,
    public matchingOptions: Array<string> = [], // for filter view
  ) {
  }
}


export class DashboardFilter {
  constructor(public questionId: string,
    public key: string,
    public value: string,
    public selectedValues: Array<string | number>, // this is used
  ) {
  }
}

export class WidgetDataChangedEventData {
  constructor(public itemIndex: number, public widgetData: any) {
  }
}

@Injectable()
export class EventsService {
  // app events
  public onEventDateRangeChanged$: EventEmitter<EventsServiceDateRange>;
  public onForceDateRange$: EventEmitter<EventsServiceDateRange>;
  public onDashboardAdded$: EventEmitter<AddedDashboard>;
  public onDashboardDeleted$: EventEmitter<DeletedDashboard>;
  public onSurveyFilterAdded$: EventEmitter<SurveyFilter>;
  public onWidgetDataChanged$: EventEmitter<WidgetDataChangedEventData>;
  public onSurveyFiltersChanged$: EventEmitter<Array<SurveyFilter>>;
  public onDashboardFiltersChanged$: EventEmitter<Array<DashboardFilter>>;
  public onQuestionScoreCalculated$: EventEmitter<QuestionScore>;
  public onBeforeHtml2canvas$: EventEmitter<null>;
  public onScoresRequested$: EventEmitter<null>;
  public onReloadObject$: EventEmitter<ReloadObjectTypes>;
  public readyComponentsForScreenshot$: EventEmitter<null>;
  public onGetMenuItem$: EventEmitter<any>;
  public onSideToggled$: EventEmitter<null>;
  public onChartLoaded$: EventEmitter<number>;
  public onScreenshotStepCompleted$: EventEmitter<number>;
  public onCompletionChartDataFetched$: EventEmitter<any>;
  public onSelfDashboardFiltersChanged: EventEmitter<any>;
  public onSelfSurveyFiltersChanged: EventEmitter<any>;
  public onProductFruitsInitialized: EventEmitter<any>; // not used yet
  public onResetDisplayedSurveys: EventEmitter<any>;

  public forcedDateRange: EventsServiceDateRange;
  private cache: AppCache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  private dateRange: EventsServiceDateRange;
  private dateRangeLabel: string;
  private readonly dateRangeKey;
  private readonly dateRangeLabelKey;

  constructor(private localStorageService: LocalStorageService,
    private configService: ConfigService) {
    this.dateRangeKey = this.configService.localStorageKeys.DateRange;
    this.dateRangeLabelKey = this.configService.localStorageKeys.DateRangeLabel;
    this.onEventDateRangeChanged$ = new EventEmitter<EventsServiceDateRange>();
    this.onForceDateRange$ = new EventEmitter<EventsServiceDateRange>();
    this.onDashboardAdded$ = new EventEmitter<AddedDashboard>();
    this.onDashboardDeleted$ = new EventEmitter<DeletedDashboard>();
    this.onSurveyFilterAdded$ = new EventEmitter<SurveyFilter>();
    this.onSurveyFiltersChanged$ = new EventEmitter<Array<SurveyFilter>>();
    this.onDashboardFiltersChanged$ = new EventEmitter<Array<DashboardFilter>>();
    this.onWidgetDataChanged$ = new EventEmitter<WidgetDataChangedEventData>();
    this.onQuestionScoreCalculated$ = new EventEmitter<QuestionScore>();
    this.onScoresRequested$ = new EventEmitter<null>();
    this.onSideToggled$ = new EventEmitter<null>();
    this.onBeforeHtml2canvas$ = new EventEmitter<null>();
    this.onChartLoaded$ = new EventEmitter<number>();
    this.onReloadObject$ = new EventEmitter<ReloadObjectTypes>();
    this.readyComponentsForScreenshot$ = new EventEmitter<null>();
    this.onScreenshotStepCompleted$ = new EventEmitter<number>();
    this.onCompletionChartDataFetched$ = new EventEmitter<any>();
    this.onSelfDashboardFiltersChanged = new EventEmitter<any>();
    this.onSelfSurveyFiltersChanged = new EventEmitter<any>();
    this.onProductFruitsInitialized = new EventEmitter<any>();
    this.onResetDisplayedSurveys = new EventEmitter<any>();
    const storedDR: any = this.localStorageService.get(this.dateRangeKey);
    const storedDRLabel: string = this.localStorageService.get(this.dateRangeLabelKey);

    if (storedDRLabel) {
      this.dateRangeLabel = storedDRLabel;
      storedDR.startDate = this.configService.getDateRangePickerOptions().ranges[storedDRLabel][0];
      storedDR.endDate = this.configService.getDateRangePickerOptions().ranges[storedDRLabel][1];
      this.dateRange = new EventsServiceDateRange(moment(storedDR.startDate), (moment(storedDR.endDate)));
    } else if (storedDR) {
      // console.log('storedDR', storedDR);
      this.dateRange = new EventsServiceDateRange(moment(storedDR.startDate), (moment(storedDR.endDate)));
    } else {
      this.dateRange =
        (new EventsServiceDateRange(moment().subtract(89, 'days'), moment()));
    }
  }

  public emitDateRangeChanged(dateRange: EventsServiceDateRange): void {
    // console.log('emitDateRangeChanged', dateRange);
    // alert('emitDateRangeChanged');
    this.dateRange = dateRange;
    this.dateRangeLabel = undefined;
    this.onEventDateRangeChanged$.emit(dateRange);
    this.localStorageService.set(this.dateRangeLabelKey, null);
    this.localStorageService.set(this.dateRangeKey, dateRange);
  }

  public emitForceDateRange(dateRange: EventsServiceDateRange): void {
    if (!_.isEqual(this.forcedDateRange, dateRange)) {
      this.forcedDateRange = dateRange;
      this.onForceDateRange$.emit(dateRange);
    }
  }

  public emitCompletionChartDataFetched(data: any): void {
    this.onCompletionChartDataFetched$.emit(data);
  }

  public emitSelfDashboardFiltersChanged(id: string): void {
    this.onSelfDashboardFiltersChanged.emit(id);
  }

  public emitSelfSurveyFiltersChanged(id: string): void {
    this.onSelfSurveyFiltersChanged.emit(id);
  }

  public emitProductFruitsInitialized(data: any): void {
    this.onProductFruitsInitialized.emit(data);
  }

  public eventDateRangeLabelChanged(label: string, dateRange: EventsServiceDateRange): void {
    this.dateRangeLabel = label;
    this.dateRange = dateRange;
    this.localStorageService.set(this.dateRangeLabelKey, label);
    this.localStorageService.set(this.dateRangeKey, dateRange);

    this.onEventDateRangeChanged$.emit(dateRange);
  }

  public emitAddedDashboard(id: number, viewId: number, title: string, dashboard: any): void {
    this.onDashboardAdded$.emit((new AddedDashboard(id, viewId, title, dashboard)));
  }

  public emitDeletedDashboard(id: number, viewId: number): void {
    this.onDashboardDeleted$.emit((new DeletedDashboard(id, viewId)));
  }

  public emitSurveyFiltersChanged(remainingFilters: Array<SurveyFilter>): void {
    this.onSurveyFiltersChanged$.emit(remainingFilters);
  }

  public emitDashboardFiltersChanged(remainingFilters: Array<DashboardFilter>): void {
    this.onDashboardFiltersChanged$.emit(remainingFilters);
  }

  public emitQuestionScoreCalculated(qs: QuestionScore): void {
    this.onQuestionScoreCalculated$.emit(qs);
  }

  public emitSurveyFilterAdded(filter: SurveyFilter): void {
    this.onSurveyFilterAdded$.emit(filter);
  }

  public emitWidgetDataChanged(wd: WidgetDataChangedEventData): void {
    this.onWidgetDataChanged$.emit(wd);
  }

  public emitScreenshotStepCompleted(step: number): void {
    this.onScreenshotStepCompleted$.emit(step);
  }

  public emitBeforeHtml2canvas(): void {
    this.onBeforeHtml2canvas$.emit();
  }

  public emitReloadObject$(objectName: ReloadObjectTypes): void {
    if (objectName === ReloadObjectTypes.Insights) {
      this.cache.clearLocalCache(this.cacheTypes.Insights, '');
    }
    this.onReloadObject$.emit(objectName);
  }

  public readyComponentsForScreenshot(): void {
    // console.log('emitHideSideMenu');
    this.readyComponentsForScreenshot$.emit(null);
  }

  public emitSideToggled$(): void {
    this.onSideToggled$.emit(null);
  }

  public emitChartLoaded(idx: number): void {
    this.onChartLoaded$.emit(idx);
  }

  public getCurrentDateRange(): EventsServiceDateRange {
    return this.dateRange;
  }

  public getCurrentDateRangeLabel(): string {
    return this.dateRangeLabel;
  }

  public emitResetDisplayedSurveys(): void {
    this.onResetDisplayedSurveys.emit();
  }
}
