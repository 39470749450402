<h5>Immediate Alert Settings</h5>
<form [formGroup]="immediateAlertForm" *ngIf="surveyEscalationSettings && immediateAlertForm">
  <!--  <span class="title"> Client Settings </span>-->
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="49%" fxLayout="column" class="settings-row margin-top-10" fxLayoutGap="10px"
      formGroupName="alertLongComments">
      <div fxFlex="100%">
        <mat-checkbox formControlName="enabled"> Long comments</mat-checkbox>
      </div>
      <div fxFlex="100%">
        <mat-form-field appearance="outline">
          <input matInput placeholder="" type="number" formControlName="value">
        </mat-form-field>
      </div>
    </div>
    <div fxFlex="49%" fxLayout="column" class="margin-top-10" fxLayoutGap="10px" formGroupName="minScore">
      <div fxFlex="100%">
        <mat-checkbox formControlName="enabled">Minimum Score</mat-checkbox>
      </div>
      <div fxFlex="100%">
        <mat-form-field appearance="outline">
          <input matInput placeholder="" type="number" formControlName="value">
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
<h5>Routing Rules for Negative Responses</h5>
<form [formGroup]="operationalFieldRulesForm" *ngIf="surveyEscalationSettings && immediateAlertForm">
  <div fxFlex="100%" fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="49%" fxLayout="column">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Operational Field Routing Rule</mat-label>
        <mat-select placeholder="Select Operational Field Routing Rule" formControlName="operationalFieldRule"
          (selectionChange)="onRoutingRuleChange()">
          <mat-option *ngFor="let f of routingRulesOfOperationalFields" [value]="f"
            [disabled]="f.isSelected">{{f.name}}</mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="30%" fxLayout="column">
      <mat-card-actions>
        <button name="addRoutingRuleButton" mat-raised-button class="mat-green margin-top-10" (click)="addRoutingRule()"
          [disabled]="!isValidRoutingRule"> Add Routing Rule</button>
      </mat-card-actions>
    </div>
  </div>
  </form>
  <!-- <br> -->
  <div fxLayout="column" fxLayoutGap="10px">
    <div fxFlex="100%" *ngIf="defaultEmails.length > 0 && selectedRoutingRules.length === 0">
      <b>**Note**:-</b> No existing rules found. As per Email Alert Settings mails will be sent to: <b>{{ defaultEmails.join(', ') }}</b>
    </div>
    <div fxLayout="row wrap">
      <div *ngFor="let rule of selectedRoutingRules; let i = index" fxFlex.gt-sm="50%" fxFlex="100%">
        <div class="m-gap p-gap">
          <mat-card>
            <mat-card-title fxLayout="row" fxLayoutAlign="start center">
              <span> {{rule.name}} </span>
              <span fxFlex></span>
              <button fxLayoutAlign="right" mat-icon-button (click)="removeRoutingRule(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-card-title>
            <hr>
            <mat-card-content>
              <div class="w-100 h-300px">
                <div fxLayout="row" fxLayoutGap="5px">
                  <!-- Left Side -->
                  <div fxFlex="50%">
                    <span> {{rule.description}}</span>
                  </div>
                  <div fxFlex="50%" style="text-align: right">
                    <strong class="pro-des">{{ rule.configuration.operationalField.value
                      }} ({{rule.configuration.operationalField.key }})</strong>
                  </div>
                </div>
                <div class="center">
                  <div *ngFor="let criteria of rule.configuration.matchingCriteria" fxLayout="row"
                    fxLayoutAlign="start center" fxLayoutGap="5px" style="border-bottom: 1px solid;">
                    <mat-chip-list aria-label="Criteria" fxFlex="50%">
                      <span *ngFor="let fieldValue of criteria.fieldValues">
                        <mat-chip aria-readonly="true">{{fieldValue}}</mat-chip>
                      </span>
                    </mat-chip-list>
                    <p class="value" fxFlex="50%" style="text-align: right;">{{ criteria.recipientEmailsMatch }}</p>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="5px">
                  <div fxFlex="50%">
                    <p class="value">{{ rule.configuration.noMatchEmails }}</p>
                  </div>
                  <!-- <div fxFlex="50%" style="text-align: right">
                    <p class="value">{{ rule.isAvailable ? 'Enabled' : 'Disabled' }}</p>
                  </div> -->
                </div>
              </div>
    
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    </div>
